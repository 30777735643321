import latino1 from "./1-12.png";
import latino2 from "./2-14.png";
import latino3 from "./3-12.png";
import latino4 from "./4-14.png";
import latino5 from "./6-11.png";
import latino6 from "./7-11.png";
import latino7 from "./8-10.png";
import latino8 from "./9-10.png";
import latino9 from "./11-8.png";
import latino10 from "./12-9.png";
import latino11 from "./13-8.png";

const Latino = [
  latino1,
  latino2,
  latino3,
  latino4,
  latino5,
  latino6,
  latino7,
  latino8,
  latino9,
  latino10,
  latino11,
];
export default Latino;
