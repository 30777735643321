import zora1 from "./1-6.webp";
import zora2 from "./2-8.webp";
import zora3 from "./3-6.webp";
import zora4 from "./4-8.webp";
import zora5 from "./5-7.webp";
import zora6 from "./6-6.webp";
import zora7 from "./7-6-1.webp";
import zora8 from "./8-5-1.webp";
import zora9 from "./9-5-1.webp";
import zora10 from "./10-4-1.webp";
import zora12 from "./10-4.webp";
import zora13 from "./11-4-1.webp";
import zora14 from "./12-5-1.webp";
import zora15 from "./14-2-1.webp";
import zora16 from "./15-2-1.webp";
import zora17 from "./16-1-1.webp";
import zora18 from "./17-1.webp";
import zora19 from "./19-1-1.webp";
import zora20 from "./20-1-1.webp";

const Zora = [
  zora1,
  zora2,
  zora3,
  zora4,
  zora5,
  zora6,
  zora7,
  zora8,
  zora9,
  zora10,
  zora12,
  zora13,
  zora14,
  zora15,
  zora16,
  zora17,
  zora18,
  zora19,
  zora20,
];

export default Zora;
