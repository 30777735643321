import React from "react";
import logo from "../../media/navbarlogo.png";
import "./Navbar.scss";
import {Link} from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navbar-container">
      <div className="logo-container">
        <img src={logo} alt="BSD Logo" className="logo" />
      </div>
      <div className="nav-item">
        <nav>
          <ul>
            <Link to="/">Residentials</Link>
          </ul>
          <ul>
            <Link to="/commercial">Commercial</Link>
          </ul>
        </nav>
      </div>
      <div className="nav-button">
        <button
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send/?phone=6287860478606&text=Halo%20Gerry,%20Saya%20bisa%20minta%20detail%20Promo%20terbaru%20produk%20BSD%20City%20https://marketing-bsdcity.id/&type=phone_number&app_absent=0",
              "_blank"
            )
          }
          className="nav-whatsapp">
          Whatsapp
        </button>
      </div>
    </div>
  );
};

export default Navbar;
