import delrey1 from "./2-16.png";
import delrey2 from "./4-16.png";
import delrey3 from "./5-14.png";
import delrey4 from "./7-12.png";
import delrey6 from "./8-11.png";
import delrey7 from "./9-11.png";
import delrey8 from "./10-10.png";
import delrey9 from "./12-10-1.png";
import delrey5 from "./13-9.png";

const Delrey = [
  delrey1,
  delrey2,
  delrey3,
  delrey4,
  delrey6,
  delrey7,
  delrey8,
  delrey9,
  delrey5,
];

export default Delrey;
