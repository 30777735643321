import myza1 from "./1.webp";
import myza2 from "./2-3.webp";
import myza3 from "./3-3.webp";
import myza4 from "./5-1.webp";
import myza5 from "./6.webp";
import myza6 from "./7-1.webp";
import myza7 from "./8-1.webp";
import myza8 from "./9.webp";

const Myza = [myza1, myza2, myza3, myza4, myza5, myza6, myza7, myza8];

export default Myza;
