import tanakayu1 from "./1-2.webp";
import tanakayu2 from "./2-4.webp";
import tanakayu4 from "./3-2.webp";
import tanakayu5 from "./4-4.webp";
import tanakayu6 from "./5-3.webp";
import tanakayu7 from "./6-2.webp";
import tanakayu8 from "./7-2.webp";
import tanakayu9 from "./8-1-1.webp";
import tanakayu10 from "./9-1.webp";

const Tanakayu = [
  tanakayu1,
  tanakayu2,
  tanakayu4,
  tanakayu5,
  tanakayu6,
  tanakayu7,
  tanakayu8,
  tanakayu9,
  tanakayu10,
];

export default Tanakayu;
