import Akasalogo from "./Akasalogo.png";
import Blessindologo from "./Blessindologo.png";
import Capitalogo from "./Capitallogo.png";
import Cascadelogo from "./Cascadelogo.png";
import Delreylogo from "./Delreylogo.png";
import Latinologo from "./Latinoslogo.png";
import Northlogo from "./Northlogo.png";
import Thelooplogo from "./Thelooplogo.png";
import Northridgelogo from "./Northridgelogo.png";

const RukoLogo = {
  Akasalogo,
  Blessindologo,
  Capitalogo,
  Cascadelogo,
  Delreylogo,
  Latinologo,
  Northlogo,
  Thelooplogo,
  Northridgelogo
};

export default RukoLogo;
