import enchante1 from "./1-7-1.webp";
import enchante2 from "./2-9-1.webp";
import enchante3 from "./3-7-1.webp";
import enchante4 from "./4-9-1.webp";
import enchante5 from "./5-8-1.webp";
import enchante6 from "./6-7-1.webp";
import enchante7 from "./7-7-1.webp";
import enchante8 from "./8-6-1.webp";
import enchante9 from "./9-6-1.webp";
import enchante10 from "./10-5-1.webp";
import enchante11 from "./11-5-1.webp";
import enchante12 from "./12-6-1.webp";
import enchante13 from "./13-5-1.webp";
import enchante14 from "./14-3-1.webp";
import enchante15 from "./15-3-1.webp";
import enchante16 from "./16-2-1.webp";
import enchante17 from "./17-1-2.webp";
import enchante18 from "./18-2-1.webp";
import enchante19 from "./19-2-1.webp";

const Enchante = [
  enchante1,
  enchante2,
  enchante3,
  enchante4,
  enchante5,
  enchante6,
  enchante7,
  enchante8,
  enchante9,
  enchante10,
  enchante11,
  enchante12,
  enchante13,
  enchante14,
  enchante15,
  enchante16,
  enchante17,
  enchante18,
  enchante19,
];
export default Enchante;
