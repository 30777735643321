import React from "react";
import Banner from "../../component/Banner/Banner";
import BannerResident from "../../media/banner-resident.webp";
import BannerResidentMobile from "../../media/banner-resident-mobile.webp";
import Promo from "../../section/HomeSection/promo/Promo";
import Launching from "../../section/HomeSection/launching/Launching";
import Statistik from "../../component/Statistik/Statistik";
import Produkbanner from "../../component/ProdukBanner/Produkbanner";
import ProdukBannerResidential from "../../media/produk-banner-residential.webp";
import Bannerpenawaranimage from "../../media/banner-penawaran-resident.webp";
import Cardhome from "../../section/HomeSection/CardHome/Cardhome";
import Bannerpenawaran from "../../component/BannerPenawaran/Bannerpenawaran";
import Surrounding from "../../component/Surrounding/Surrounding";
import Benefit from "../../section/HomeSection/Benefit/Benefit";
import Footer from "../../component/Footer/Footer";
import "./Home.scss";
import ScrollToHashElement from "../../component/ScrollToHash/ScrollToHash";

const Home = () => {
  return (
    <div>
      <ScrollToHashElement />
      <Banner imageA={BannerResident} imageB={BannerResidentMobile} />
      <Promo />
      <Launching />
      <Statistik />
      <Produkbanner produk={ProdukBannerResidential} text={"Residentials"} />
      <Cardhome />
      <Bannerpenawaran
        produk={Bannerpenawaranimage}
        text={"Pilihan Ruko BSD City"}
        subtext={"Start 6 Juta/Bulan Tanpa DP"}
        buttontext={"More Info"}
        link={"/commercial"}
      />
      <Surrounding />
      <Benefit />
      <Footer />
    </div>
  );
};

export default Home;
