import React from "react";
import "./Bannerpenawaran.scss";
import {Link} from "react-router-dom";
const Bannerpenawaran = ({produk, text, subtext, buttontext, link}) => {
  return (
    <div
      className="banner-penawaran"
      style={{backgroundImage: `url(${produk})`}}>
      <img className="produk-banner" alt="" />
      <div className="produk-banner-title">{text}</div>
      <div className="produk-banner-subtitle">{subtext}</div>
      <div className="buttonwrapper">
        <Link to={link}>
          <button className="button">{buttontext}</button>
        </Link>
      </div>
    </div>
  );
};

export default Bannerpenawaran;
