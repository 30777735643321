import teraria1 from "./1-3-1.webp";
import teraria2 from "./2-5.webp";
import teraria3 from "./3-3-1.webp";
import teraria4 from "./5-4.webp";
import teraria5 from "./6-3.webp";
import teraria6 from "./7-3.webp";
import teraria7 from "./9-2.webp";
import teraria8 from "./10-1.webp";
import teraria9 from "./11-1.webp";
import teraria10 from "./12-2.webp";
import teraria11 from "./13-1.webp";
import teraria12 from "./14.webp";
import teraria13 from "./15.webp";

const Teraria = [
  teraria1,
  teraria2,
  teraria3,
  teraria4,
  teraria5,
  teraria6,
  teraria7,
  teraria8,
  teraria9,
  teraria10,
  teraria11,
  teraria12,
  teraria13,
];

export default Teraria;
