import cascade1 from "./1-16.png";
import cascade2 from "./2-18.png";
import cascade3 from "./3-16.png";
import cascade4 from "./4-18.png";
import cascade5 from "./5-16.png";
import cascade6 from "./7-13.png";
import cascade7 from "./10-10.png";
import cascade8 from "./12-10-1.png";
import cascade9 from "./13-9.png";

const Cascade = [
  cascade1,
  cascade2,
  cascade3,
  cascade4,
  cascade5,
  cascade6,
  cascade7,
  cascade8,
  cascade9,
];

export default Cascade;
