import React from "react";
import "./Footer.scss";
import map from "../../media/map.png";
import logo from "../../media/footer-logo.webp";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="img-map">
        <img id="lokasi" className="img" alt="map" src={map} />
      </div>
      <div className="footer">
        <div className="divider"></div>
        <div className="footer-content">
          <div className="footer-logo">
            <img alt="footerlogo" src={logo} className="logo" />
          </div>
          <div className="footer-description">
            <div className="footer-title">Marketing Gallery</div>
            <div className="footer-subtitle">BSD City</div>
            <div className="footer-des">Sinarmasland</div>
            <div className="footer-alamat">
              City, Jl. BSD Grand Boulevard, Sampora, Kec. Cisauk, Kabupaten
              Tangerang, Banten 15345
              <br />
              Contact Marketing : +628111378606
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
