import layton1 from "./Layton.webp";
import layton2 from "./8-8-1.webp";
import layton3 from "./9-8-1.webp";
import layton4 from "./10-7-1.webp";
import layton5 from "./11-7-1.webp";
import layton6 from "./12-8-1.webp";
import layton7 from "./13-7-1.webp";
import layton8 from "./14-5-1.webp";

const Layton = [
  layton1,
  layton2,
  layton3,
  layton4,
  layton5,
  layton6,
  layton7,
  layton8,
];

export default Layton;
