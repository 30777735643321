import hera4 from "./t8-12.webp";
import hera2 from "./t8-14.webp";
import hera3 from "./t8-17.webp";
import hera1 from "./t9-1.webp";
import hera5 from "./t9-2.webp";
import hera6 from "./t9-3.webp";
import hera7 from "./t9-5.webp";
import hera8 from "./t9-6.webp";
import hera9 from "./t9-7.webp";
import hera10 from "./t9-8.webp";

const Hera = [
  hera1,
  hera2,
  hera3,
  hera4,
  hera5,
  hera6,
  hera7,
  hera8,
  hera9,
  hera10,
];

export default Hera;
