import React from "react";
import "./Launching-com.scss";
import LaunchingCom from "./Xlane_Hardsell.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faCircleCheck,
  faHouse,
  faLayerGroup,
  faShower,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Launchingcom = () => {
  return (
    <div className="launching-container">
      <div className="divider" />
      <div id="newlaunchingwestv" className="launching">
        <div className="launching-img">
          <img className="img" alt="logoimage" src={LaunchingCom} />
        </div>
        <div className="launching-content">
          <div className="launching-start">New Launching</div>
          <div className="launching-title">Ruko XLane </div>
          <div className="launching-benefit">
            <hr />
            <div className="benefit-title">Benefits</div>
            <div className="benefit">
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Tanpa DP
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Discount up to 10%
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Voucher Elektronik up to 40 Juta
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Cashback up to 25 Juta
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Strategic Location
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;10k Daily Vehicle Traffic
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;78k Surrounding Market
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Easy Access
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;High Potential Captive Market
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Surrounded by Many Residential
            </div>
            <hr />
          </div>
          <div className="launching-icon">
            <FontAwesomeIcon color="#FFCE6C" icon={faArrowsUpDownLeftRight} />
            <span>&nbsp; : 54m² &nbsp;</span>
            <FontAwesomeIcon color="#FFCE6C" icon={faHouse} />
            <span>&nbsp; : 167m² &nbsp;</span>
            <FontAwesomeIcon color="#FFCE6C" icon={faLayerGroup} />
            <span>&nbsp; : 3 &nbsp;</span>
            <FontAwesomeIcon color="#FFCE6C" icon={faToilet} />
            <span>&nbsp; : 3 &nbsp;</span>
          </div>
          <div className="launching-circle">
            <div className="circle-text">
              18
              <br />
              Juta/Bulan
            </div>
          </div>
          <button
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=6287860478606&text=Halo%20Gerry,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Ruko%20(New%20Launch%20Ruko%20Xlane)%20https://marketing-bsdcity.id/%20&type=phone_number&app_absent=0",
                "_blank"
              )
            }
            className="launching-button">
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
      <div className="divider1" />
    </div>
  );
};

export default Launchingcom;
