import askara1 from "./Askara.webp";
import askara2 from "./2-11.webp";
import askara3 from "./3-9.webp";
import askara4 from "./4-11.webp";
import askara5 from "./5-10.webp";
import askara6 from "./t8-12.webp";
import askara7 from "./t8-17.webp";
import askara8 from "./t9-5.webp";
import askara9 from "./t9-7.webp";

const Askara = [
  askara1,
  askara2,
  askara3,
  askara4,
  askara5,
  askara6,
  askara7,
  askara8,
  askara9,
];

export default Askara;
