import blizfield1 from "./1-5.webp";
import blizfield2 from "./2-7.webp";
import blizfield3 from "./3-5.webp";
import blizfield4 from "./4-7.webp";
import blizfield5 from "./5-6.webp";
import blizfield6 from "./6-5.webp";
import blizfield7 from "./7-5.webp";
import blizfield8 from "./8-4.webp";
import blizfield9 from "./9-4.webp";
import blizfield10 from "./10-3.webp";
import blizfield11 from "./11-3.webp";
import blizfield12 from "./13-3.webp";

const Blizfield = [
  blizfield1,
  blizfield2,
  blizfield3,
  blizfield4,
  blizfield5,
  blizfield6,
  blizfield7,
  blizfield8,
  blizfield9,
  blizfield10,
  blizfield11,
  blizfield12,
];

export default Blizfield;
