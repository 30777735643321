import Myzalogo from "./Myzalogo.png";
import logo2 from "./Fleekhauz.png";
import Terarialogo from "./Terarialogo.webp";
import Tanakayulogo from "./Tanakayulogo.png";
import Heralogo from "./Heralogo.webp";
import Askaralogo from "./Askaralogo.webp";
import Eonnalogo from "./Eonnalogo.png";
import Blizzfieldlogo from "./Blizzfieldlogo.png";
import Caeluslogo from "./Caeluslogo.png";
import Zoralogo from "./Zoralogo.png";
import Echantelogo from "./Enchantelogo.png";
import laytonlogo from "./Laytonlogo.png";

const RumahLogo = {
  Myzalogo,
  logo2,
  Terarialogo,
  Tanakayulogo,
  Heralogo,
  Askaralogo,
  Eonnalogo,
  Blizzfieldlogo,
  Caeluslogo,
  Zoralogo,
  Echantelogo,
  laytonlogo,
};

export default RumahLogo;
