import blessindo1 from "./1-1.png";
import blessindo2 from "./2-1.png";
import blessindo3 from "./3-1.png";
import blessindo4 from "./7.png";
import blessindo5 from "./8.png";
import blessindo6 from "./10.png";

const Blessindo = [
  blessindo1,
  blessindo2,
  blessindo3,
  blessindo4,
  blessindo5,
  blessindo6,
];

export default Blessindo;
