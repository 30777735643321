import React from "react";
import "./Statistik.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faLocationDot,
  faRoad,
} from "@fortawesome/free-solid-svg-icons";

const Statistik = () => {
  return (
    <div className="statistik-container">
      <div className="statistik-title">Live, Work & Play in One Place</div>
      <div className="statistik-number">
        <div className="statistik">
          <div className="judul">More Than</div>
          <div className="angka">6.000</div>
          <div className="subjudul">Ha Land Area</div>
        </div>
        <div className="statistik">
          <div className="judul">More Than</div>
          <div className="angka">136</div>
          <div className="subjudul">Residential</div>
        </div>
      </div>
      <div className="statistik-icon">
        <div className="icon">
          <FontAwesomeIcon size="2x" icon={faLocationDot} />
          <br />
          Strategic Location
        </div>
        <div className="icon">
          <FontAwesomeIcon size="2x" icon={faBuilding} />
          <br />
          Premium Facilities
        </div>
        <div className="icon">
          <FontAwesomeIcon size="2x" icon={faRoad} />
          <br />
          Easy Access
        </div>
      </div>
    </div>
  );
};

export default Statistik;
