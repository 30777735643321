import eonna1 from "./1-4.webp";
import eonna2 from "./2-6.webp";
import eonna3 from "./3-4.webp";
import eonna4 from "./4-6.webp";
import eonna5 from "./5-5.webp";
import eonna6 from "./6-4.webp";
import eonna7 from "./7-4.webp";
import eonna8 from "./8-3.webp";
import eonna9 from "./9-3.webp";
import eonna10 from "./10-2.webp";
import eonna12 from "./11-2.webp";
import eonna13 from "./12-3.webp";
import eonna14 from "./13-2.webp";
import eonna15 from "./14-1.webp";
import eonna16 from "./15-1.webp";
import eonna17 from "./16.webp";

const Eonna = [
  eonna1,
  eonna2,
  eonna3,
  eonna4,
  eonna5,
  eonna6,
  eonna7,
  eonna8,
  eonna9,
  eonna10,
  eonna12,
  eonna13,
  eonna14,
  eonna15,
  eonna16,
  eonna17,
];

export default Eonna;
