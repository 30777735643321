import React from "react";
import "./Cardcommercial.scss";
import data from "./data.json";
import RukoLogo from "../../../media/RukoLogo/index";
import Card from "../../../component/Card/Card";
import Akasa from "../../../media/Ruko/Akasa/index";
import Blessindo from "../../../media/Ruko/Blessindo/index";
import Capital from "../../../media/Ruko/Capital/index";
import Cascade from "../../../media/Ruko/Cascade/index";
import Delrey from "../../../media/Ruko/Delrey/index";
import Latino from "../../../media/Ruko/Latino/index";
import North from "../../../media/Ruko/North/index";
import Theloop from "../../../media/Ruko/Theloop/index";
import Northridge from "../../../media/Ruko/Northridge/northridge";
import Westvillage from "../../../media/Ruko/westvillage/westvillage";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons";

const commercialMap = {
  Akasa,
  Blessindo,
  Capital,
  Cascade,
  Delrey,
  Latino,
  North,
  Theloop,
  Westvillage,
  Northridge,
};

const Cardcommercial = () => {
  return (
    <div className="cardcommercial-container">
      {data.commercial.map((card, index) => {
        // Determine the logo dynamically
        const myzaLogo = RukoLogo[card.logo];

        return (
          <Card
            key={index}
            carousel={commercialMap[card.imagesKey]}
            logo={myzaLogo} // Dynamically import logo images
            title={card.title}
            price={card.price}
            subprice={card.subprice}
            details={card.details}
            whatsappLink={card.whatsappLink}
            iconcard={faLayerGroup} // Pass the WhatsApp link
          />
        );
      })}
    </div>
  );
};

export default Cardcommercial;
