import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Surrounding.scss";
import TheBreeze from "../../media/The-Breeze.webp";
import Qbigmall from "../../media/QBIG.webp";
import Prasetyamulya from "../../media/Prasetya-Mulya.webp";
import ocean from "../../media/Ocean-Park.webp";
import ice from "../../media/ICE-BSD.webp";
import ekahospital from "../../media/Eka-Hospital.webp";
import golf from "../../media/Damai-Golf.webp";
import binus from "../../media/Binus.webp";
import alazhar from "../../media/Al-Azhar.webp";
import aeon from "../../media/Aeon-Mall.webp";
import atma from "../../media/Atmajaya.webp";
const Surrounding = ({ data, images }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveSlide(next),
  };
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [
    { src: TheBreeze, title: "TheBreeze" },
    { src: Qbigmall, title: "QBIG Mall" },
    { src: Prasetyamulya, title: "Prasetya Mulya" },
    { src: ocean, title: "Ocean Park" },
    { src: ice, title: "ICE BSD" },
    { src: ekahospital, title: "Eka Hospital" },
    { src: golf, title: "Damai Indah Golf" },
    { src: binus, title: "Binus University" },
    { src: alazhar, title: "Al Azhar School" },
    { src: aeon, title: "Aeon Mall" },
    { src: atma, title: "Atma Jaya" },
  ];
  return (
    <div className="surounding">
      <div className="surrounding-title">Surrounding Area</div>

      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div
            className={`slider-container ${
              index === activeSlide ? "active" : ""
            }`}
            key={index}
          >
            <img src={slide.src} alt={slide.title} className="image" />
            <div className="judul-image">{slide.title}</div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Surrounding;
