import React from "react";
import "./ProdukBanner.scss";

const Produkbanner = ({ text, produk }) => {
  return (
    <div
      className="produk-banner-container"
      style={{ backgroundImage: `url(${produk})` }}
    >
      <img className="produk-banner" alt="" />
      <div className="produk-banner-title">{text}</div>
    </div>
  );
};

export default Produkbanner;
