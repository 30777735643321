import React, { useEffect, useState } from "react";
import "./Banner.scss";

const Banner = ({ imageA, imageB }) => {
  const [currentImage, setCurrentImage] = useState(
    window.innerWidth <= 768 ? imageB : imageA
  );

  useEffect(() => {
    const handleResize = () => {
      setCurrentImage(window.innerWidth <= 768 ? imageB : imageA);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [imageA, imageB]);
  return (
    <div className="banner-container">
      <img className="banner" src={currentImage} alt="responsive" />
      <div className="text-content">
        <div className="title">BSD City A Place Where You Call Home</div>
        <div className="description">
          BSD City adalah kota terencana di Tangerang Selatan, Banten,
          Indonesia.BSD City juga merupakan Kota yang dikembangkan oleh salah
          satu developer ternama yaitu Sinarmasland. Salah satu dari enam kota
          mandiri terencana di Tangerang, proyek ini memiliki luas lahan
          terbesar di Indonesia, dengan 6.000 hektar. Pengembangan BSD City
          dilakukan secara bertahap dan telah selesai. BSD City memiliki pusat
          perbelanjaan, hotel, sekolah, dan universitas serta jalan tol yang
          menghubungkan ke Jakarta dan kota-kota sekitarnya. Kota BSD yang hijau
          dan bebas banjir telah berkembang pesat menjadi kota teknologi canggih
          digital.
        </div>
      </div>
    </div>
  );
};

export default Banner;
