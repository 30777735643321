import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss"
import Home from "./pages/Home/Home";
import Commercial from "./pages/Commercial/Commercial";
import Navbar from "./component/Navbar/Navbar";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const fungsiganteng = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6287860478606&text=Halo%20Gerry,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20https://marketing-bsdcity.id/&type=phone_number&app_absent=0";
  window.location.href = whatsappLink;
};

function App() {
  return (
    <div className="App">
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/commercial/" element={<Commercial />} />
      </Routes>
      <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=6287860478606&text=Halo%20Gerry,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20https://marketing-bsdcity.id/&type=phone_number&app_absent=0"
        >
          <FloatingWhatsApp />
        </a>
        <div className="floatingwamobile">
          <button onClick={fungsiganteng} className="buttonmobile">
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Whatsapp Sekarang
          </button>
        </div>
    </Router>
    </div>
  );
}

export default App;
